// src/services/projectService.ts
import axios from "axios";
import type { Project } from "../types/project";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001/api";

export const projectService = {
  async getProjects(): Promise<Project[]> {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No authentication token found");
      }

      const response = await axios.get(`${API_URL}/projects`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching projects:", error);
      throw error;
    }
  },

  async createProject(projectData: Omit<Project, "id">): Promise<Project> {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No authentication token found");
      }

      const response = await axios.post(`${API_URL}/projects`, projectData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating project:", error);
      throw error;
    }
  },
};
