import React, { useState, useEffect } from "react";
import { Card } from "../ui/card";
import { Button } from "../ui/button";
import { Alert, AlertDescription } from "../ui/alert";
import { Plus, Edit2, Trash2, X } from "lucide-react";
import axios from "axios";

interface Project {
  _id: string;
  name: string;
  type:
    | "Aerial Photography"
    | "Construction"
    | "Mapping"
    | "Real Estate"
    | "Other";
  status: "Pending" | "In Progress" | "Completed" | "On Hold";
  startDate: string;
  dueDate: string;
  location: string;
  description: string;
  budget: number;
}

interface FormData {
  name: string;
  type: Project["type"];
  status: Project["status"];
  startDate: string;
  dueDate: string;
  location: string;
  description: string;
  budget: number;
}

const ProjectManagement = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    type: "Aerial Photography",
    status: "Pending",
    startDate: "",
    dueDate: "",
    location: "",
    description: "",
    budget: 0,
  });

  const fetchProjects = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Authentication required");
        setLoading(false);
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/projects`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setProjects(response.data);
      setError(null);
    } catch (error) {
      console.error("Error fetching projects:", error);
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          setError("Authentication expired. Please log in again.");
        } else if (error.response?.status === 404) {
          setError("No projects found.");
        } else {
          setError("Failed to fetch projects. Please try again later.");
        }
      } else {
        setError("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };

  const createProject = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Authentication required");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/projects`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setProjects([...projects, response.data]);
      setFormData({
        name: "",
        type: "Aerial Photography",
        status: "Pending",
        startDate: "",
        dueDate: "",
        location: "",
        description: "",
        budget: 0,
      });
      setShowForm(false);
      setError(null);
    } catch (error) {
      console.error("Error creating project:", error);
      if (axios.isAxiosError(error)) {
        setError(error.response?.data?.message || "Failed to create project");
      } else {
        setError("An unexpected error occurred");
      }
    }
  };

  const updateProject = async (id: string, updates: Partial<Project>) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/projects/${id}`,
        updates,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      await fetchProjects();
    } catch (error) {
      console.error("Error updating project:", error);
      if (axios.isAxiosError(error)) {
        setError(error.response?.data?.message || "Failed to update project");
      }
    }
  };

  const deleteProject = async (id: string) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${process.env.REACT_APP_API_URL}/projects/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      await fetchProjects();
    } catch (error) {
      console.error("Error deleting project:", error);
      if (axios.isAxiosError(error)) {
        setError(error.response?.data?.message || "Failed to delete project");
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "budget" ? Number(value) : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await createProject();
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#F47920] border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <Card>
        <Card.Header>
          <Card.Title className="flex justify-between items-center">
            Projects
            <Button
              onClick={() => setShowForm(true)}
              className="bg-orange-500 hover:bg-orange-600"
            >
              <Plus className="w-4 h-4 mr-2" /> New Project
            </Button>
          </Card.Title>
        </Card.Header>

        <Card.Content>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {showForm && (
            <div className="mb-6 p-4 border rounded-lg">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">New Project</h3>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setShowForm(false)}
                >
                  <X className="w-4 h-4" />
                </Button>
              </div>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Project Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="w-full p-2 border rounded-md"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Type
                    </label>
                    <select
                      name="type"
                      value={formData.type}
                      onChange={handleInputChange}
                      className="w-full p-2 border rounded-md"
                    >
                      <option value="Aerial Photography">
                        Aerial Photography
                      </option>
                      <option value="Construction">Construction</option>
                      <option value="Mapping">Mapping</option>
                      <option value="Real Estate">Real Estate</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Start Date
                    </label>
                    <input
                      type="date"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleInputChange}
                      className="w-full p-2 border rounded-md"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Due Date
                    </label>
                    <input
                      type="date"
                      name="dueDate"
                      value={formData.dueDate}
                      onChange={handleInputChange}
                      className="w-full p-2 border rounded-md"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Location
                    </label>
                    <input
                      type="text"
                      name="location"
                      value={formData.location}
                      onChange={handleInputChange}
                      className="w-full p-2 border rounded-md"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Budget
                    </label>
                    <input
                      type="number"
                      name="budget"
                      value={formData.budget}
                      onChange={handleInputChange}
                      className="w-full p-2 border rounded-md"
                      min="0"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Description
                  </label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded-md"
                    rows={3}
                  />
                </div>
                <div className="flex justify-end space-x-2">
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setShowForm(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="bg-orange-500 hover:bg-orange-600"
                  >
                    Create Project
                  </Button>
                </div>
              </form>
            </div>
          )}

          <div className="space-y-4">
            {projects.length === 0 ? (
              <div className="text-center py-4 text-gray-500">
                No projects yet. Click "New Project" to create one.
              </div>
            ) : (
              projects.map((project) => (
                <div
                  key={project._id}
                  className="flex items-center justify-between p-4 border rounded-lg"
                >
                  <div>
                    <h3 className="font-medium">{project.name}</h3>
                    <p className="text-sm text-gray-500">{project.type}</p>
                    <span
                      className={`inline-block px-2 py-1 text-xs rounded ${
                        project.status === "Completed"
                          ? "bg-green-100 text-green-800"
                          : project.status === "In Progress"
                          ? "bg-blue-100 text-blue-800"
                          : "bg-gray-100 text-gray-800"
                      }`}
                    >
                      {project.status}
                    </span>
                  </div>
                  <div className="flex space-x-2">
                    <Button
                      variant="outline"
                      onClick={() =>
                        updateProject(project._id, { status: "In Progress" })
                      }
                    >
                      <Edit2 className="w-4 h-4" />
                    </Button>
                    <Button
                      variant="destructive"
                      onClick={() => deleteProject(project._id)}
                    >
                      <Trash2 className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              ))
            )}
          </div>
        </Card.Content>
      </Card>
    </div>
  );
};

export default ProjectManagement;
