import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { projectService } from "../services/projectService";
import { Project, ProjectFile } from "../types/project";

interface ProjectContextType {
  projects: Project[];
  loading: boolean;
  error: string | null;
  getProject: (id: number) => Project | undefined;
  createProject: (project: Omit<Project, "id">) => Promise<Project>;
  updateProject: (id: number, updates: Partial<Project>) => Promise<void>;
  deleteProject: (id: number) => Promise<void>;
  uploadProjectFile: (projectId: number, file: File) => Promise<void>;
  deleteProjectFile: (projectId: number, fileId: string) => Promise<void>;
  fetchProjects: () => Promise<void>;
}

const ProjectContext = createContext<ProjectContextType | undefined>(undefined);

export function ProjectProvider({ children }: { children: React.ReactNode }) {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      setError(null);
      const fetchedProjects = await projectService.getProjects();
      setProjects(fetchedProjects);
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to fetch projects");
      console.error("Error fetching projects:", err);
    } finally {
      setLoading(false);
    }
  };

  const getProject = useCallback(
    (id: number) => projects.find((p) => p.id === id),
    [projects]
  );

  const createProject = async (projectData: Omit<Project, "id">) => {
    try {
      setError(null);
      const newProject = await projectService.createProject(projectData);
      setProjects((prev) => [...prev, newProject]);
      return newProject;
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to create project");
      throw err;
    }
  };

  const updateProject = async (id: number, updates: Partial<Project>) => {
    try {
      setError(null);
      const response = await fetch(`/api/projects/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(updates),
      });

      if (!response.ok) {
        throw new Error("Failed to update project");
      }

      const updatedProject = await response.json();
      setProjects((prev) =>
        prev.map((p) => (p.id === id ? updatedProject : p))
      );
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to update project");
      throw err;
    }
  };

  const deleteProject = async (id: number) => {
    try {
      setError(null);
      await fetch(`/api/projects/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setProjects((prev) => prev.filter((p) => p.id !== id));
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to delete project");
      throw err;
    }
  };

  const uploadProjectFile = async (projectId: number, file: File) => {
    try {
      setError(null);
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(`/api/projects/${projectId}/files`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const updatedProject = await response.json();
      setProjects((prev) =>
        prev.map((p) => (p.id === projectId ? updatedProject : p))
      );
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to upload file");
      throw err;
    }
  };

  const deleteProjectFile = async (projectId: number, fileId: string) => {
    try {
      setError(null);
      const response = await fetch(
        `/api/projects/${projectId}/files/${fileId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete file");
      }

      setProjects((prev) =>
        prev.map((p) => {
          if (p.id === projectId) {
            return {
              ...p,
              files: p.files.filter((f) => f.id !== fileId),
            };
          }
          return p;
        })
      );
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to delete file");
      throw err;
    }
  };

  const value = {
    projects,
    loading,
    error,
    getProject,
    createProject,
    updateProject,
    deleteProject,
    uploadProjectFile,
    deleteProjectFile,
    fetchProjects,
  };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
}

export function useProjects() {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error("useProjects must be used within a ProjectProvider");
  }
  return context;
}

export default ProjectContext;
