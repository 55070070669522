import React from "react";
import { Nav } from "./Nav";
import { Footer } from "./Footer";
import { ContactButtons } from "./ContactButtons";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Nav />
      <main className="flex-grow">{children}</main>
      <ContactButtons />
      <Footer />
    </div>
  );
};
