import axios, { AxiosError } from "axios";

interface User {
  id: string;
  name: string;
  email: string;
  role: string;
}

interface AuthResponse {
  token: string;
  user: User;
}

interface LoginCredentials {
  email: string;
  password: string;
}

interface RegisterData {
  name: string;
  email: string;
  password: string;
}

interface ResetPasswordData {
  token: string;
  password: string;
  confirmPassword: string;
}

interface AuthService {
  login: (credentials: LoginCredentials) => Promise<AuthResponse>;
  register: (data: RegisterData) => Promise<AuthResponse>;
  forgotPassword: (data: { email: string }) => Promise<void>;
  resetPassword: (data: ResetPasswordData) => Promise<void>;
  verifyEmail: (token: string) => Promise<void>;
  logout: () => void;
  getCurrentUser: () => User | null;
  getToken: () => string | null;
}

const API_URL =
  process.env.REACT_APP_API_URL || "https://aerviz-backend.herokuapp.com/api";

// Configure axios defaults and interceptors
axios.defaults.baseURL = API_URL;

// Request interceptor to add auth header
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle auth errors
axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

class AuthServiceImpl implements AuthService {
  async login(credentials: LoginCredentials): Promise<AuthResponse> {
    try {
      const response = await axios.post(`${API_URL}/auth/login`, credentials);
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
      }
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async register(data: RegisterData): Promise<AuthResponse> {
    try {
      const response = await axios.post(`${API_URL}/auth/register`, data);
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
      }
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async forgotPassword(data: { email: string }): Promise<void> {
    try {
      await axios.post(`${API_URL}/auth/forgot-password`, data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async resetPassword(data: ResetPasswordData): Promise<void> {
    try {
      await axios.post(`${API_URL}/auth/reset-password`, data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async verifyEmail(token: string): Promise<void> {
    try {
      await axios.post(`${API_URL}/auth/verify-email`, { token });
    } catch (error) {
      throw this.handleError(error);
    }
  }

  logout(): void {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    // Optional: Clear any other stored data
    // localStorage.clear(); // Be careful with this as it clears ALL localStorage
  }

  getCurrentUser(): User | null {
    try {
      const userStr = localStorage.getItem("user");
      return userStr ? JSON.parse(userStr) : null;
    } catch (error) {
      this.logout(); // Clear potentially corrupted data
      return null;
    }
  }

  getToken(): string | null {
    return localStorage.getItem("token");
  }

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  private handleError(error: unknown): Error {
    if (axios.isAxiosError(error)) {
      // Handle specific HTTP errors
      const message = error.response?.data?.message || error.message;

      switch (error.response?.status) {
        case 401:
          this.logout();
          return new Error("Authentication failed. Please login again.");
        case 403:
          return new Error("You don't have permission to perform this action.");
        case 404:
          return new Error("Resource not found.");
        case 422:
          return new Error("Validation failed: " + message);
        case 429:
          return new Error("Too many requests. Please try again later.");
        case 500:
          return new Error("Server error. Please try again later.");
        default:
          return new Error(message || "An unexpected error occurred.");
      }
    }

    // Handle non-Axios errors
    if (error instanceof Error) {
      return error;
    }

    return new Error("An unexpected error occurred.");
  }

  // Helper method to refresh token if needed
  async refreshToken(): Promise<void> {
    try {
      const response = await axios.post(`${API_URL}/auth/refresh-token`, {
        token: this.getToken(),
      });

      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
      }
    } catch (error) {
      this.logout();
      throw this.handleError(error);
    }
  }

  // Helper method to update user data
  updateUserData(userData: Partial<User>): void {
    const currentUser = this.getCurrentUser();
    if (currentUser) {
      const updatedUser = { ...currentUser, ...userData };
      localStorage.setItem("user", JSON.stringify(updatedUser));
    }
  }
}

export const authService = new AuthServiceImpl();

export const register = async (data: RegisterData): Promise<AuthResponse> => {
  try {
    const response = await axios.post(`${API_URL}/auth/register`, data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      // Pass through the specific error code and message
      throw {
        message: error.response.data.message,
        code: error.response.data.code,
      };
    }
    throw new Error("Registration failed");
  }
};

const login = async (credentials: LoginCredentials): Promise<AuthResponse> => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, credentials, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true, // Important for CORS with credentials
    });

    if (response.data.token) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.user));
    }
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

// Type guard to check if an error is an AxiosError
export function isAxiosError(error: unknown): error is AxiosError {
  return axios.isAxiosError(error);
}
