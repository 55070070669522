import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { Menu, X, ChevronDown, User } from "lucide-react";
import { ContactButtons } from "./ContactButtons";

export const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  const services = [
    {
      path: "/services/aerial-photography-videography",
      name: "Aerial Photography & Videography",
    },
    {
      path: "/services/residential-commercial-real-estate",
      name: "Residential & Commercial Real Estate",
    },
    {
      path: "/services/construction-monitoring",
      name: "Construction Monitoring",
    },
    {
      path: "/services/mapping",
      name: "Mapping",
    },
    {
      path: "/services/orthomosaic-photos",
      name: "Orthomosaic Photos",
    },
    {
      path: "/services/photogrammetry",
      name: "Photogrammetry",
    },
  ];

  return (
    <nav className="sticky top-0 z-50 bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0">
              <img
                src="/assets/images/logo2.png"
                alt="Aerviz Logo"
                className="h-12"
              />
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex md:items-center md:space-x-8">
            <Link
              to="/"
              className="font-subhead text-gray-800 hover:text-[#F47920] font-medium"
            >
              Home
            </Link>

            {/* Services Dropdown */}
            <div className="relative group">
              <Link
                to="/services"
                className="font-subhead text-gray-800 hover:text-[#F47920] font-medium flex items-center peer"
              >
                Services
                <svg
                  className="ml-2 w-4 h-4 transition-transform duration-200 group-hover:rotate-180"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M16.939 7.939L12 12.879L7.06098 7.939L4.93898 10.061L12 17.121L19.061 10.061L16.939 7.939Z" />
                </svg>
              </Link>

              <div
                className="hidden absolute left-0 mt-2 w-72 bg-white shadow-lg rounded-md py-2 
                transition-all duration-200 ease-in-out 
                group-hover:block hover:block
                before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-2 before:-translate-y-full"
              >
                {services.map((service, index) => (
                  <Link
                    key={index}
                    to={service.path}
                    className="font-subhead block px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-[#F47920] transition-colors"
                  >
                    {service.name}
                  </Link>
                ))}
              </div>
            </div>

            <Link
              to="/drone-repair-services"
              className="font-subhead text-gray-800 hover:text-[#F47920] font-medium"
            >
              Drone Repair
            </Link>

            <Link
              to="/blog"
              className="font-subhead text-gray-800 hover:text-[#F47920] font-medium"
            >
              Blog
            </Link>

            {/* User Menu */}
            {user ? (
              <div className="relative group">
                <button className="flex items-center space-x-2 text-gray-800 hover:text-[#F47920] font-medium">
                  <User className="w-5 h-5" />
                  <ChevronDown className="w-4 h-4 transition-transform duration-200 group-hover:rotate-180" />
                </button>
                <div
                  className="hidden absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md py-2 
                  group-hover:block hover:block
                  before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-2 before:-translate-y-full"
                >
                  <Link
                    to="/dashboard"
                    className="font-subhead block px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-[#F47920]"
                  >
                    Dashboard
                  </Link>
                  <Link
                    to="/projects"
                    className="font-subhead block px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-[#F47920]"
                  >
                    Projects
                  </Link>
                  <Link
                    to="/analytics"
                    className="font-subhead block px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-[#F47920]"
                  >
                    Analytics
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="font-subhead w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-[#F47920]"
                  >
                    Logout
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex items-center space-x-4">
                <Link
                  to="/login"
                  className="font-subhead text-[#F47920] hover:text-[#d15d08] font-medium"
                >
                  Login
                </Link>
                <Link
                  to="/signup"
                  className="font-subhead bg-[#F47920] text-white px-4 py-2 rounded-md hover:bg-[#d15d08] transition-colors duration-300"
                >
                  Sign Up
                </Link>
              </div>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="font-subhead text-gray-800 hover:text-[#F47920] focus:outline-none"
              aria-label="Toggle menu"
            >
              {isOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        {isOpen && (
          <div className="md:hidden bg-white border-t border-gray-200">
            <div className="font-subhead px-2 pt-2 pb-3 space-y-1">
              <Link
                to="/"
                className="font-subhead block px-3 py-2 text-gray-800 hover:text-[#F47920] font-medium"
                onClick={() => setIsOpen(false)}
              >
                Home
              </Link>

              {services.map((service, index) => (
                <Link
                  key={index}
                  to={service.path}
                  className="font-subhead block px-3 py-2 text-gray-800 hover:text-[#F47920] font-medium"
                  onClick={() => setIsOpen(false)}
                >
                  {service.name}
                </Link>
              ))}

              <Link
                to="/drone-repair-services"
                className="font-subhead block px-3 py-2 text-gray-800 hover:text-[#F47920] font-medium"
                onClick={() => setIsOpen(false)}
              >
                Drone Repair
              </Link>

              <Link
                to="/blog"
                className="font-subhead block px-3 py-2 text-gray-800 hover:text-[#F47920] font-medium"
                onClick={() => setIsOpen(false)}
              >
                Blog
              </Link>

              {user ? (
                <>
                  <Link
                    to="/dashboard"
                    className="font-subhead block px-3 py-2 text-gray-800 hover:text-[#F47920] font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    Dashboard
                  </Link>
                  <Link
                    to="/projects"
                    className="font-subhead block px-3 py-2 text-gray-800 hover:text-[#F47920] font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    Projects
                  </Link>
                  <Link
                    to="/analytics"
                    className="font-subhead block px-3 py-2 text-gray-800 hover:text-[#F47920] font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    Analytics
                  </Link>
                  <button
                    onClick={() => {
                      handleLogout();
                      setIsOpen(false);
                    }}
                    className="font-subhead block w-full text-left px-3 py-2 text-gray-800 hover:text-[#F47920] font-medium"
                  >
                    Logout
                  </button>
                </>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="font-subhead block px-3 py-2 text-gray-800 hover:text-[#F47920] font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    Login
                  </Link>
                  <Link
                    to="/signup"
                    className="font-subhead block px-3 py-2 text-gray-800 hover:text-[#F47920] font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    Sign Up
                  </Link>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Contact Buttons - Hidden on mobile */}
      <div className="hidden md:block">
        <ContactButtons />
      </div>
    </nav>
  );
};

export default Nav;
