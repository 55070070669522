// src/components/layout/Footer.tsx
import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="font-subhead bg-white py-16">
      <div className="container mx-auto px-4">
        {/* Footer columns */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-16">
          {/* Quick Links */}
          <div className="text-center md:text-left">
            <h3 className="font-['Space_Grotesk'] text-lg font-semibold text-gray-900 mb-4">
              Company
            </h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/about"
                  className="font-['Space_Grotesk'] text-gray-600 hover:text-[#F47920]"
                >
                  Who we are
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="font-['Space_Grotesk'] text-gray-600 hover:text-[#F47920]"
                >
                  Contact us
                </Link>
              </li>
              <li>
                <Link
                  to="/careers"
                  className="font-['Space_Grotesk'] text-gray-600 hover:text-[#F47920]"
                >
                  Careers
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className="font-['Space_Grotesk'] text-gray-600 hover:text-[#F47920]"
                >
                  Blog
                </Link>
              </li>
            </ul>
          </div>

          {/* Services */}
          <div className="text-center md:text-left">
            <h3 className="font-['Space_Grotesk'] text-lg font-semibold text-gray-900 mb-4">
              Services
            </h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/services/aerial-photography-videography"
                  className="font-['Space_Grotesk'] text-gray-600 hover:text-[#F47920]"
                >
                  Aerial Photography
                </Link>
              </li>
              <li>
                <Link
                  to="/services/construction-monitoring"
                  className="font-['Space_Grotesk'] text-gray-600 hover:text-[#F47920]"
                >
                  Construction Monitoring
                </Link>
              </li>
              <li>
                <Link
                  to="/services/mapping"
                  className="font-['Space_Grotesk'] text-gray-600 hover:text-[#F47920]"
                >
                  Mapping
                </Link>
              </li>
              <li>
                <Link
                  to="/services/orthomosaic-photos"
                  className="font-['Space_Grotesk'] text-gray-600 hover:text-[#F47920]"
                >
                  Orthomosaic Photos
                </Link>
              </li>
              <li>
                <Link
                  to="/services/photogrammetry"
                  className="font-['Space_Grotesk'] text-gray-600 hover:text-[#F47920]"
                >
                  Photogrammetry
                </Link>
              </li>
              <li>
                <Link
                  to="/services/residential-commercial-real-estate"
                  className="font-['Space_Grotesk'] text-gray-600 hover:text-[#F47920]"
                >
                  Real Estate
                </Link>
              </li>
            </ul>
          </div>

          {/* Professional Services */}
          <div className="text-center md:text-left">
            <h3 className="font-['Space_Grotesk'] text-lg font-semibold text-gray-900 mb-4">
              Professional Services
            </h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/maintenance"
                  className="font-['Space_Grotesk'] text-gray-600 hover:text-[#F47920]"
                >
                  Maintenance
                </Link>
              </li>
              <li>
                <Link
                  to="/consulting"
                  className="font-['Space_Grotesk'] text-gray-600 hover:text-[#F47920]"
                >
                  Consulting
                </Link>
              </li>
              <li>
                <Link
                  to="/training"
                  className="font-['Space_Grotesk'] text-gray-600 hover:text-[#F47920]"
                >
                  Training
                </Link>
              </li>
              <li>
                <Link
                  to="/drone-repair-services"
                  className="font-['Space_Grotesk'] text-gray-600 hover:text-[#F47920]"
                >
                  Repairs
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="text-center md:text-left">
            <h3 className="font-['Space_Grotesk'] text-lg font-semibold text-gray-900 mb-4">
              Contact Us
            </h3>
            <ul className="space-y-2">
              <li className="font-['Space_Grotesk'] text-gray-600">
                <a href="tel:+16124916057" className="hover:text-[#F47920]">
                  +1 (612) 491-6057
                </a>
              </li>
              <li className="font-['Space_Grotesk'] text-gray-600">
                <a
                  href="mailto:info@aerviz.co"
                  className="hover:text-[#F47920]"
                >
                  info@aerviz.co
                </a>
              </li>
              <li className="font-['Space_Grotesk'] text-gray-600">
                Twin Cities, MN
              </li>
              <li className="font-['Space_Grotesk'] text-gray-600">
                Duluth-Superior, MN
              </li>
            </ul>
          </div>
        </div>

        {/* Copyright */}
        <div className="text-center mt-16 pt-8 border-t border-gray-200">
          <p className="font-['Space_Grotesk'] text-gray-500">
            Copyright © {new Date().getFullYear()} Aerviz. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
