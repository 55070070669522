import React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cn } from "../../lib/utils";
import { Loader2 } from "lucide-react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "default" | "outline" | "ghost" | "link" | "destructive";
  size?: "sm" | "md" | "lg";
  isLoading?: boolean;
  loadingText?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      variant = "default",
      size = "md",
      isLoading = false,
      loadingText,
      leftIcon,
      rightIcon,
      disabled,
      type = "button",
      asChild = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    const baseStyles = cn(
      "font-subhead", // Base font family
      "font-medium", // Base font weight
      "inline-flex items-center justify-center rounded-md",
      "transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
      "disabled:pointer-events-none disabled:opacity-50"
    );

    const variants = {
      default: cn(
        "bg-[#F47920] text-white hover:bg-[#FBB785]",
        "focus-visible:ring-[#F47920]",
        "font-semibold" // Slightly bolder for primary buttons
      ),
      outline: cn(
        "border border-[#F47920] text-[#F47920]",
        "hover:bg-[#F47920] hover:text-white",
        "font-medium"
      ),
      ghost: cn("text-[#F47920] hover:bg-[#FBB785]/10", "font-medium"),
      link: cn(
        "text-[#F47920] underline-offset-4 hover:underline",
        "p-0 h-auto",
        "font-medium"
      ),
      destructive: cn(
        "bg-red-500 text-white hover:bg-red-600",
        "focus-visible:ring-red-500",
        "font-semibold" // Slightly bolder for destructive actions
      ),
    };

    const sizes = {
      sm: cn("h-8 px-3 text-sm"),
      md: cn("h-10 px-4 text-base"), // Added explicit text size
      lg: cn("h-12 px-6 text-lg font-semibold"), // Slightly bolder for large buttons
    };

    return (
      <Comp
        ref={ref}
        type={type}
        disabled={disabled || isLoading}
        className={cn(
          baseStyles,
          variants[variant],
          variant !== "link" && sizes[size],
          className
        )}
        {...props}
      >
        {isLoading ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            {loadingText || children}
          </>
        ) : (
          <>
            {leftIcon && <span className="mr-2">{leftIcon}</span>}
            {children}
            {rightIcon && <span className="ml-2">{rightIcon}</span>}
          </>
        )}
      </Comp>
    );
  }
);

Button.displayName = "Button";

export { Button };
