import { useState } from "react";
import { Phone, Mail } from "lucide-react";

export const ContactButtons = () => {
  const [isPhoneExpanded, setIsPhoneExpanded] = useState(false);
  const [isMailExpanded, setIsMailExpanded] = useState(false);

  return (
    // Add hidden md:flex to hide on mobile but show on medium screens and up
    <div className="fixed left-4 top-24 hidden md:flex flex-col gap-4 z-50">
      <button
        className={`font-subhead group flex items-center bg-[#FBB785] rounded-full transition-all duration-300 hover:bg-[#F47920] ${
          isPhoneExpanded ? "w-50" : "w-12"
        }`}
        onMouseEnter={() => setIsPhoneExpanded(true)}
        onMouseLeave={() => setIsPhoneExpanded(false)}
        onClick={() => (window.location.href = "tel:+17634821293")}
      >
        <div className="flex items-center justify-center w-12 h-12 shrink-0">
          <Phone className="text-white" size={24} />
        </div>
        <span
          className={`font-subhead text-white font-bold whitespace-nowrap overflow-hidden transition-all duration-300 ${
            isPhoneExpanded ? "opacity-100 mr-4" : "opacity-0 w-0"
          }`}
        >
          +1 (763) 482-1293
        </span>
      </button>

      <button
        className={`font-subhead group flex items-center bg-[#FBB785] rounded-full transition-all duration-300 hover:bg-[#F47920] ${
          isMailExpanded ? "w-50" : "w-12"
        }`}
        onMouseEnter={() => setIsMailExpanded(true)}
        onMouseLeave={() => setIsMailExpanded(false)}
        onClick={() => (window.location.href = "mailto:info@aerviz.co")}
      >
        <div className="flex items-center justify-center w-12 h-12 shrink-0">
          <Mail className="text-white" size={24} />
        </div>
        <span
          className={`font-subhead text-white font-bold whitespace-nowrap overflow-hidden transition-all duration-300 ${
            isMailExpanded ? "opacity-100 mr-4" : "opacity-0 w-0"
          }`}
        >
          info@aerviz.co
        </span>
      </button>
    </div>
  );
};

export default ContactButtons;
