import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { ProjectProvider } from "./contexts/ProjectContext";
import { ProtectedRoute } from "./components/auth/ProtectedRoute";
import { Layout } from "./components/layout/Layout";
import ProjectManagement from "./components/project/ProjectManagement";

// Lazy load all components
const HomePage = lazy(() => import("./pages/HomePage"));
const ServicesPage = lazy(() => import("./pages/ServicesPage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const CareersPage = lazy(() => import("./pages/CareersPage"));
const MaintenancePage = lazy(() => import("./pages/MaintenancePage"));
const ConsultingPage = lazy(() => import("./pages/ConsultingPage"));
const TrainingPage = lazy(() => import("./pages/TrainingPage"));
const ProjectFilesPage = lazy(() => import("./pages/ProjectFilesPage"));
const BlogPage = lazy(() => import("./pages/BlogPage"));
const DroneRepair = lazy(() => import("./pages/DroneRepair"));

// Auth Components
const LoginForm = lazy(() => import("./components/auth/LoginForm"));
const SignupForm = lazy(() => import("./components/auth/SignupForm"));
const ForgotPassword = lazy(() => import("./components/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./components/auth/ResetPassword"));
const VerifyEmail = lazy(() => import("./components/auth/VerifyEmail"));

// Dashboard Components
const EnhancedDashboard = lazy(
  () => import("./components/dashboard/EnhancedDashboard")
);
const AdminDashboard = lazy(
  () => import("./components/dashboard/AdminDashboard")
);
const AnalyticsDashboard = lazy(
  () => import("./components/dashboard/AnalyticsDashboard")
);
const ReportGeneratorWrapper = lazy(
  () => import("./components/reports/ReportGeneratorWrapper")
);

// Service Pages
const AerialPhotography = lazy(
  () => import("./pages/services/AerialPhotography")
);
const ConstructionMonitoring = lazy(
  () => import("./pages/services/ConstructionMonitoring")
);
const Mapping = lazy(() => import("./pages/services/Mapping"));
const OrthomosaicMapping = lazy(
  () => import("./pages/services/OrthomosaicMapping")
);
const Photogrammetry = lazy(() => import("./pages/services/Photogrammetry"));
const RealEstate = lazy(() => import("./pages/services/RealEstate"));

// Loading Component
const LoadingSpinner = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#F47920] border-t-transparent"></div>
  </div>
);

const serviceRoutes = [
  {
    path: "/services/aerial-photography-videography",
    element: <AerialPhotography />,
  },
  {
    path: "/services/construction-monitoring",
    element: <ConstructionMonitoring />,
  },
  {
    path: "/services/mapping",
    element: <Mapping />,
  },
  {
    path: "/services/orthomosaic-photos",
    element: <OrthomosaicMapping />,
  },
  {
    path: "/services/photogrammetry",
    element: <Photogrammetry />,
  },
  {
    path: "/services/residential-commercial-real-estate",
    element: <RealEstate />,
  },
];

function App() {
  return (
    <AuthProvider>
      <ProjectProvider>
        <Router>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              {/* Auth Routes */}
              <Route path="/login" element={<LoginForm />} />
              <Route path="/signup" element={<SignupForm />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route path="/verify-email/:token" element={<VerifyEmail />} />

              {/* Protected Dashboard Routes */}
              <Route
                path="/dashboard/"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <EnhancedDashboard />
                    </Layout>
                  </ProtectedRoute>
                }
              />

              <Route
                path="/analytics"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <AnalyticsDashboard />
                    </Layout>
                  </ProtectedRoute>
                }
              />

              <Route
                path="/reports/:projectId"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <ReportGeneratorWrapper />
                    </Layout>
                  </ProtectedRoute>
                }
              />

              <Route
                path="/projects/:projectId/files"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <ProjectFilesPage />
                    </Layout>
                  </ProtectedRoute>
                }
              />

              <Route
                path="/projects"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <ProjectManagement />
                    </Layout>
                  </ProtectedRoute>
                }
              />

              {/* Protected Admin Routes */}
              <Route
                path="/admin/*"
                element={
                  <ProtectedRoute requireAdmin>
                    <Layout>
                      <AdminDashboard />
                    </Layout>
                  </ProtectedRoute>
                }
              />

              {/* Public Routes */}
              <Route
                path="/"
                element={
                  <Layout>
                    <HomePage />
                  </Layout>
                }
              />

              <Route
                path="/services"
                element={
                  <Layout>
                    <ServicesPage />
                  </Layout>
                }
              />

              {/* Service Routes */}
              {serviceRoutes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<Layout>{route.element}</Layout>}
                />
              ))}

              {/* Other Public Routes */}
              <Route
                path="/blog"
                element={
                  <Layout>
                    <BlogPage />
                  </Layout>
                }
              />
              <Route
                path="/drone-repair-services"
                element={
                  <Layout>
                    <DroneRepair />
                  </Layout>
                }
              />
              <Route
                path="/about"
                element={
                  <Layout>
                    <AboutPage />
                  </Layout>
                }
              />
              <Route
                path="/contact"
                element={
                  <Layout>
                    <ContactPage />
                  </Layout>
                }
              />
              <Route
                path="/careers"
                element={
                  <Layout>
                    <CareersPage />
                  </Layout>
                }
              />
              <Route
                path="/maintenance"
                element={
                  <Layout>
                    <MaintenancePage />
                  </Layout>
                }
              />
              <Route
                path="/consulting"
                element={
                  <Layout>
                    <ConsultingPage />
                  </Layout>
                }
              />
              <Route
                path="/training"
                element={
                  <Layout>
                    <TrainingPage />
                  </Layout>
                }
              />

              {/* 404 Route */}
              <Route
                path="*"
                element={
                  <Layout>
                    <div className="min-h-screen flex items-center justify-center">
                      <div className="text-center">
                        <h1 className="text-4xl font-bold text-[#F47920] mb-4">
                          404
                        </h1>
                        <p className="text-xl text-gray-600">Page not found</p>
                        <Link
                          to="/"
                          className="mt-4 inline-block bg-[#F47920] text-white px-6 py-2 rounded-md hover:bg-[#FBB785] transition-colors"
                        >
                          Go Home
                        </Link>
                      </div>
                    </div>
                  </Layout>
                }
              />
            </Routes>
          </Suspense>
        </Router>
      </ProjectProvider>
    </AuthProvider>
  );
}

export default App;
