import * as React from "react";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  ref?: React.ForwardedRef<HTMLDivElement>;
}

interface CardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  ref?: React.ForwardedRef<HTMLDivElement>;
}

interface CardTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  ref?: React.ForwardedRef<HTMLHeadingElement>;
}

interface CardContentProps extends React.HTMLAttributes<HTMLDivElement> {
  ref?: React.ForwardedRef<HTMLDivElement>;
}

const CardComponent = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className = "", ...props }, ref) => (
    <div
      ref={ref}
      className={`rounded-lg border bg-card text-card-foreground shadow-sm ${className}`}
      {...props}
    />
  )
);
CardComponent.displayName = "Card";

const CardHeader = React.forwardRef<HTMLDivElement, CardHeaderProps>(
  ({ className = "", ...props }, ref) => (
    <div
      ref={ref}
      className={`flex flex-col space-y-1.5 p-6 ${className}`}
      {...props}
    />
  )
);
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef<HTMLHeadingElement, CardTitleProps>(
  ({ className = "", ...props }, ref) => (
    <h3
      ref={ref}
      className={`text-2xl font-semibold leading-none tracking-tight ${className}`}
      {...props}
    />
  )
);
CardTitle.displayName = "CardTitle";

const CardContent = React.forwardRef<HTMLDivElement, CardContentProps>(
  ({ className = "", ...props }, ref) => (
    <div ref={ref} className={`p-6 pt-0 ${className}`} {...props} />
  )
);
CardContent.displayName = "CardContent";

// Define the compound component type
interface CardType extends React.ForwardRefExoticComponent<CardProps> {
  Header: typeof CardHeader;
  Title: typeof CardTitle;
  Content: typeof CardContent;
}

// Create the compound component
const Card = CardComponent as CardType;
Card.Header = CardHeader;
Card.Title = CardTitle;
Card.Content = CardContent;

export { Card, CardHeader, CardTitle, CardContent };
export type { CardProps, CardHeaderProps, CardTitleProps, CardContentProps };
